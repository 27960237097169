/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
* BROWSER POLYFILLS
*/

import 'core-js/es7/array';
/** Evergreen browsers require these. **/
import 'core-js/es6/reflect';
/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js';
/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone'; // Included with Angular CLI.
// Provides a polyfill for Nodelist.prototype.forEach() to all Browsers supporting ES5.
import 'nodelist-foreach-polyfill';

/**
 * Required to support Web Animations `@angular/animation`.
 * Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
 **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';

if (!Element.prototype.matches) {
  Element.prototype.matches = (<any>Element).prototype.msMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function closest(selector) {
    let element = this;
    while (element && element.nodeType === 1) {
      if (element.matches(selector)) {
        return element;
      }
      element = element.parentNode;
    }
    return null;
  };
}

if (!('path' in Event.prototype)) {
  Object.defineProperty(Event.prototype, 'path', {
    get: function() {
      const path = [];
      let currentElem = this.target;
      while (currentElem) {
        path.push(currentElem);
        currentElem = currentElem.parentElement;
      }
      if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
        path.push(document);
      }

      if (path.indexOf(window) === -1) {
        path.push(window);
      }

      return path;
    }
  });
}

// Support for Combo Chart in IE 11
(function (window: any, document: any) {
  function MouseEvent(eventType, params) {
    params = params || {bubbles: false, cancelable: false};
    const mouseEvent = document.createEvent('MouseEvent');
    mouseEvent.initMouseEvent(eventType, params.bubbles, params.cancelable, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    return mouseEvent;
  }

  if (!!window.MSInputMethodContext && !!document.documentMode || document.documentMode === 10) {
    MouseEvent.prototype = Event.prototype;

    window.MouseEvent = MouseEvent;
  }
})(window, document);

